<style scoped>
.container {
  position: absolute;
  top: 200px;
  display: flex;
  color: #fff;
  justify-content: space-between;
}
.center {
  display: flex;
  flex-direction: column;
}
.center-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.line {
  width: 60px;
  height: 4px;
  background-color: #ffffff;
  border-radius: 2px;
}
.report {
  width: 100%;
  height: 580px;
  border-radius: 5px;
  background: url("~@/assets/images/home_1.png") 100% 100% no-repeat;
  background-size: cover;
}

.el-card >>> .el-card__body {
  font-size: 36px;
  line-height: 36px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
}
.testing,
.equipment {
  width: 100%;
  height: 280px;
  border-radius: 5px;
}
.testing {
  background: url("~@/assets/images/home_2.png") 100% 100% no-repeat;
  background-size: cover;
}
.equipment {
  background: url("~@/assets/images/home_3.png") 100% 100% no-repeat;
  background-size: cover;
}
.profile,
.personal {
  width: 100%;
  height: 280px;
  border-radius: 5px;
}
.profile {
  background: url("~@/assets/images/home_4.png") 100% 100% no-repeat;
  margin-bottom: 20px;
  background-size: cover;
}
.collect {
  background: url("~@/assets/images/home_5.png") 100% 100% no-repeat;
  background-size: cover;
  width: 100%;
  height: 280px;
  border-radius: 5px;
}
.personal {
  background: url("~@/assets/images/home_6.png") 100% 100% no-repeat;
  background-size: cover;
}
.card {
  cursor: pointer;
}
.card:hover {
  box-shadow: 5px 5px 5px rgba(000, 000, 000, 0.2);
}
</style>
<template>
  <el-row :gutter="20" class="container" id="container">
    <el-col :span="7" class="left">
      <el-card class="report card" @click.native="toReport">
        <span>我的报告</span>
        <!-- <div class="line"></div> -->
      </el-card>
    </el-col>
    <el-col :span="10" class="center">
      <el-row class="center-top" :gutter="20">
        <el-col :span="12">
          <el-card class="testing card" @click.native="toDetect">
            <span>我的检测</span>
            <!-- <div class="line"></div> -->
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card class="equipment card" @click.native="toEquipment">
            <span>我的设备</span>
            <!-- <div class="line"></div> -->
          </el-card>
        </el-col>
      </el-row>
      <div class="center-bottom">
        <el-card class="collect card" @click.native="toCollect">
          <span>我的收藏</span>
          <!-- <div class="line"></div> -->
        </el-card>
      </div>
    </el-col>
    <el-col :span="7" class="right">
      <el-card class="profile card" @click.native="toProfile">
        <span>企业简介</span>
        <!-- <div class="line"></div> -->
      </el-card>
      <el-card class="personal card" @click.native="toPersonal">
        <span>个人中心</span>
        <!-- <div class="line"></div> -->
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "home",
  methods: {
    //个人中心
    toPersonal() {
      this.$router.push("/personal");
    },
    //企业简介
    toProfile() {
      this.$router.push("/profile");
    },
    //我的报告
    toReport() {
      this.$router.push("/report");
    },
    //我的收藏
    toCollect() {
      this.$router.push("/collect");
    },
    //我的检测
    toDetect() {
      this.$router.push("/detect");
    },
    //我的设备
    toEquipment() {
      this.$router.push("/equipment");
    },
  },
};
</script>

